import React from "react";
import { graphql, Link } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import Seo from "../components/seo";
import ProjectArticle from "../components/ProjectArticle";

export const query = graphql`
  query CategoryBySlug($uid: String!) {
    prismicCategory(uid: { eq: $uid }) {
      data {
        name {
          text
        }
      }
    }
    allPrismicProject(filter: { data: { category: { uid: { eq: $uid } } } }) {
      nodes {
        type
        uid
        data {
          ...project
        }
      }
    }
  }
`;

const Category = ({ data }) => {
  const { prismicCategory, allPrismicProject } = data;
  // console.log(prismicCategory);
  // console.log(allPrismicProject.nodes[0]);
  return (
    <div className='wrapper'>
      <Seo
        pageTitle={prismicCategory.data.name.text}
        pageDescription={"desc google here"}
        pageImage='https://images.prismic.io/sisto-studios/905ac41b-19ac-45f2-8da1-6235d04a746f_sisto-architecture-9.png'
        template='template-category'
        page={true}
      />

      <div className='content md:px-md'>
        {allPrismicProject.nodes.length === 0 && (
          <div className='msg text-lg h-screen py-lg'>
            No projects found in this category :(, try another one.
          </div>
        )}
        {allPrismicProject.nodes.map((node, i) => (
          <div className='mb-md--' key={i}>
            <ProjectArticle input={node.data} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default withPreview(Category);
